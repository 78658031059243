import React,{useState,useEffect} from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { nFormat } from '../Vendor/common';
import { useNavigate } from 'react-router';
import DateTimePicker from 'react-datetime-picker';
import BBox from './bBox';
import { ListGroup, Button, Spinner } from 'react-bootstrap';
import SelectGroup from './atoms/SelectGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faCheckCircle, faFileInvoice, faRightFromBracket, faSearch } from '@fortawesome/free-solid-svg-icons';
const defaultMiscellaneous = {
    currencies: null,
    conditions: null,
    conditionWD: null,
    soldBySeller: null,
    terminal: null,
    date: null,
    bb: 0,
    bbDate: null,
    currentBb: null,
    expenses: null
}
const defaultClosing = {
    closing: null,
    summary: [],
    denoms: []
}
const BusinessBox = () => {
    const [miscellaneous,setMiscellaneous] = useState(defaultMiscellaneous),
        [handleData,setHandleData] = useState(false),
        [isClosing,setIsClosing] = useState(true)/** Always show close */,
        [currDistribution,setCurrDistribution] = useState([]),
        [dateFilter,setDateFilter] = useState(new Date()),
        [closing,setClosing] = useState(defaultClosing),
        [currencySummary,setCurrencySummary] = useState([]),
        [denomTurn, setDenomTurn] = useState([]),
        [denomTotal, setDenomTotal] = useState([]),
        [closers, setClosers] = useState([]),
        [whoClosed, setWhoClosed] = useState(0),
        [closeLoading,setCloseLoading] = useState(false),
        navigate = useNavigate();
    useEffect(async()=>{
        try{
            const dF = new Date(dateFilter),
            date = dF.getFullYear() + '-' + (dF.getMonth()+1) + '-' + dF.getDate();
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API+'get/general/data',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                date: date
            }),
                data = resource.data;
            if(!data.status){
                Swal.fire({
                    icon:'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                setHandleData(false);
                return;
            }
            /**check amount */            
            let checkAmount = false;
            for(let i=0;i<data.conditionWD.length;i++){
                const obj = data.conditionWD[i],
                curr = obj.currency;
                for(let c=0;c<curr.length;c++){                    
                    if(parseFloat(curr[c].val)>0){
                        //There is at least one amount
                        checkAmount = true;
                        break;
                    }
                }
            }
            setMiscellaneous({
                currencies: data.currencies,
                conditions: data.conditions,
                conditionWD: data.conditionWD,
                soldBySeller: data.soldByUser,
                terminal: data.terminal,
                date: data.date,
                currentBb: data.currentBb,
                expenses: data.expenses
            });
            //There is at least one amount
            if(checkAmount)
                setHandleData(true);

            //set data of closing found
            setClosing(data.closing);

            /**SAVE CURRENCIES*/
            let currSumm = [];
            if(data.currencies){                
                for(let c=0;c<data.currencies.length;c++){
                    let obj = data.currencies[c];
                    obj.amount = 0;
                    currSumm.push(obj);
                }
                if(data.conditionWD){
                    data.conditionWD.map((e)=>{
                        e.currency.map((element)=>{
                            for(let counter=0;counter<currSumm.length;counter++){
                                if(currSumm[counter].currency_id==element.currency_id){
                                    currSumm[counter].amount += parseFloat(element.val);
                                }
                            }
                        })
                    })
                }                                
            }
            setCurrencySummary(currSumm);
            /**Users Data */
            setClosers(data.users);
        } catch(error){
            Swal.fire({
                icon:'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    },[]);
    useEffect(()=>{
        if(denomTurn)
        {
            let arrTotal = [],
                amountCalc = 0;
            for(let c=0;c<denomTurn.length;c++)
            {
                const dTurnObj = denomTurn[c];
                if(arrTotal.length){
                    let checkIfThereCurrency = false,
                        idxFound = null;
                    for(let p=0;p<arrTotal.length;p++)
                    {
                        const pObj = arrTotal[p];
                        if(pObj.currency==dTurnObj.currencyId){
                            checkIfThereCurrency = true;
                            idxFound = p;
                            break;
                        }
                    }
                    if(checkIfThereCurrency){
                        amountCalc = 
                            ( 2000 * dTurnObj.den.dosMil ) +
                            ( 1000 * dTurnObj.den.mil ) +
                            ( 500 * dTurnObj.den.quinientos ) + 
                            ( 200 * dTurnObj.den.dosCientos ) + 
                            ( 100 * dTurnObj.den.cien ) + 
                            ( 50 * dTurnObj.den.cincuenta ) + 
                            ( 25 * dTurnObj.den.veinteYCinco ) +
                            ( 10 * dTurnObj.den.diez ) + 
                            ( 5 * dTurnObj.den.cinco ) +
                            ( 1 * dTurnObj.den.uno ) +
                            ( 0.50 * dTurnObj.den.puntoCinquenta ) +
                            ( 0.25 * dTurnObj.den.puntoVeinteCinco ) +
                            ( 0.20 * dTurnObj.den.puntoVeinte ) +
                            ( 0.15 * dTurnObj.den.puntoQuince ) +
                            ( 0.10 * dTurnObj.den.puntoDiez ) +
                            ( 0.05 * dTurnObj.den.puntoCinco ) +
                            ( 0.01 * dTurnObj.den.puntoUno );
                        arrTotal[idxFound].total += amountCalc;
                    } else {
                        amountCalc = 
                            ( 2000 * dTurnObj.den.dosMil ) +
                            ( 1000 * dTurnObj.den.mil ) +
                            ( 500 * dTurnObj.den.quinientos ) + 
                            ( 200 * dTurnObj.den.dosCientos ) + 
                            ( 100 * dTurnObj.den.cien ) + 
                            ( 50 * dTurnObj.den.cincuenta ) + 
                            ( 25 * dTurnObj.den.veinteYCinco ) +
                            ( 10 * dTurnObj.den.diez ) + 
                            ( 5 * dTurnObj.den.cinco ) +
                            ( 1 * dTurnObj.den.uno ) + 
                            ( 0.50 * dTurnObj.den.puntoCinquenta ) +
                            ( 0.25 * dTurnObj.den.puntoVeinteCinco ) +
                            ( 0.20 * dTurnObj.den.puntoVeinte ) +
                            ( 0.15 * dTurnObj.den.puntoQuince ) +
                            ( 0.10 * dTurnObj.den.puntoDiez ) +
                            ( 0.05 * dTurnObj.den.puntoCinco ) + 
                            ( 0.01 * dTurnObj.den.puntoUno );
                        arrTotal.push({
                            currency: dTurnObj.currencyId,
                            total: amountCalc
                        });
                    }
                } else {
                    amountCalc = 
                            ( 2000 * dTurnObj.den.dosMil ) +
                            ( 1000 * dTurnObj.den.mil ) +
                            ( 500 * dTurnObj.den.quinientos ) + 
                            ( 200 * dTurnObj.den.dosCientos ) + 
                            ( 100 * dTurnObj.den.cien ) + 
                            ( 50 * dTurnObj.den.cincuenta ) + 
                            ( 25 * dTurnObj.den.veinteYCinco ) +
                            ( 10 * dTurnObj.den.diez ) + 
                            ( 5 * dTurnObj.den.cinco ) +
                            ( 1 * dTurnObj.den.uno ) + 
                            ( 0.50 * dTurnObj.den.puntoCinquenta ) +
                            ( 0.25 * dTurnObj.den.puntoVeinteCinco ) +
                            ( 0.20 * dTurnObj.den.puntoVeinte ) +
                            ( 0.15 * dTurnObj.den.puntoQuince ) +
                            ( 0.10 * dTurnObj.den.puntoDiez ) +
                            ( 0.05 * dTurnObj.den.puntoCinco ) + 
                            ( 0.01 * dTurnObj.den.puntoUno );
                    arrTotal.push({
                        currency: dTurnObj.currencyId,
                        total: amountCalc
                    });
                }                
            }
            setDenomTotal(arrTotal);
            /** Update Distribution State */
            for(let contador=0;contador<arrTotal.length;contador++){
                /**There is not currDistribution */
                if(currDistribution.length<1){
                    setCustomDistribution(5,parseInt(arrTotal[contador].currency),parseFloat(arrTotal[contador].total));
                    break;
                }
                /**Modify just there are differences */
                for(let counterCurrDist=0;counterCurrDist<currDistribution.length;counterCurrDist++){
                    if(parseInt(currDistribution[counterCurrDist].currency)==parseInt(arrTotal[contador].currency)&&parseFloat(currDistribution[counterCurrDist].amount)!=parseFloat(arrTotal[contador].total)){
                        setCustomDistribution(5,parseInt(arrTotal[contador].currency),parseFloat(arrTotal[contador].total));
                    }
                }
                /**If not there */
                let isThere = false;
                for(let counterCurrDist=0;counterCurrDist<currDistribution.length;counterCurrDist++){
                    if(parseInt(currDistribution[counterCurrDist].currency)==parseInt(arrTotal[contador].currency)){
                        isThere = true;
                        break;
                    }
                }
                if(!isThere){
                    setCustomDistribution(5,parseInt(arrTotal[contador].currency),parseFloat(arrTotal[contador].total));
                }
            }            
        }        
    },[denomTurn]);
    const closeBusinessBox = (e) => {
        e.preventDefault();
        setIsClosing(true);
    }
    // eslint-disable-next-line no-unused-vars
    const cancelCloseBusinessBox = (e) => {
        e.preventDefault();
        setIsClosing(false);
    }
    const setDistribution = (input) => {
        const condition = parseInt( input.target.getAttribute('cond') ),
            currency = parseInt( input.target.getAttribute('curr') ),
            amount = parseFloat( input.target.value ),
            localDistribution = [...currDistribution];
        let inArr = false;
        for(let i=0;i<localDistribution.length;i++){
            const obj = localDistribution[i];
            if(obj.currency==currency&&obj.condition==condition){
                localDistribution[i].amount = amount;
                inArr = true;
                break;
            }
        }
        if(!inArr){
            localDistribution.push({condition:condition,currency:currency,amount:amount});
        }
        setCurrDistribution(localDistribution);
    }
    const setCustomDistribution = (condition,currency,amount) => {
        const localDistribution = [...currDistribution];
        let inArr = false;
        for(let i=0;i<localDistribution.length;i++){
            const obj = localDistribution[i];
            if(obj.currency==currency&&obj.condition==condition){
                localDistribution[i].amount = amount;
                inArr = true;
                break;
            }
        }
        if(!inArr){
            localDistribution.push({condition:condition,currency:currency,amount:amount});
        }
        setCurrDistribution(localDistribution);
    }
    const processCloseBusinessBox = async (e) => {
        e.preventDefault();
        setCloseLoading(true);
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API+'process/close/business/box',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                distribution: currDistribution,
                bb: miscellaneous.bb,
                date: miscellaneous.bbDate,
                denomTurn: denomTurn,
                denomTotal: denomTotal,
                whoClosed: whoClosed
            }),
                data = resource.data;
            setCloseLoading(false);
            if(!data.status){
                Swal.fire({
                    icon:'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                return;
            }
            Swal.fire({
                icon:'success',
                title: 'Éxito',
                text: data.msg
            }).then(()=>{
                // eslint-disable-next-line no-undef
                window.open(process.env.REACT_APP_PATH_APP+data.path,"","width=1024,height=950");
                navigate('/bb/history/'+data.bb);
            });
        } catch(error){
            setCloseLoading(false);
            Swal.fire({
                icon:'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    const searchClosingByDate = async () => {
        if(dateFilter==null){
            Swal.fire({
                icon:'warning',
                title: 'Advertencia',
                text: 'Debe seleccionar al menos una fecha para realizar esta busqueda'
            });
            return;
        }
        const dF = new Date(dateFilter),
        date = dF.getFullYear() + '-' + (dF.getMonth()+1) + '-' + dF.getDate();
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API+'get/general/data',{
                token: localStorage.getItem('OSHEN-LOC-STR-TK'),
                date: date
            }),
            data = resource.data;
            if(!data.status){
                Swal.fire({
                    icon:'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
                setHandleData(false);
                return;
            }

            /**check amount */            
            let checkAmount = false;
            for(let i=0;i<data.conditionWD.length;i++){
                const obj = data.conditionWD[i],
                curr = obj.currency;
                for(let c=0;c<curr.length;c++){                    
                    if(parseFloat(curr[c].val)>0){
                        //There is at least one amount
                        checkAmount = true;
                        break;
                    }
                }
            }

            setMiscellaneous({
                currencies: data.currencies,
                conditions: data.conditions,
                conditionWD: data.conditionWD,
                soldBySeller: data.soldByUser,
                terminal: data.terminal,                
                bb: data.bb,
                bbDate: data.dateBb,
                date: data.date,
                currentBb: data.currentBb,
                expenses: data.expenses
            });
            //There is at least one amount
            if(checkAmount)
                setHandleData(true);
            else
                setHandleData(false);

            //set data of closing found
            setClosing(data.closing);

            /**SAVE CURRENCIES*/
            let currSumm = [];
            if(data.currencies){                
                for(let c=0;c<data.currencies.length;c++){
                    let obj = data.currencies[c];
                    obj.amount = 0;
                    currSumm.push(obj);
                }
                if(data.conditionWD){
                    data.conditionWD.map((e)=>{
                        e.currency.map((element)=>{
                            for(let counter=0;counter<currSumm.length;counter++){
                                if(currSumm[counter].currency_id==element.currency_id){
                                    currSumm[counter].amount += parseFloat(element.val);
                                }
                            }
                        })
                    })
                }                                
            }
            setCurrencySummary(currSumm);

        } catch(error){
            Swal.fire({
                icon:'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    const handleDenom = (input) => {
        const id = input.target.id,
            value = (input.target.value) ? input.target.value : 0,
            currency = input.target.getAttribute("curr");

        let arr = [...denomTurn],
            checkCurr = false;
        for(let c=0;c<arr.length;c++){
            const obj = arr[c];
            if(obj.currencyId==currency){
                arr[c].den[id] = value;
                checkCurr = true;
                break;
            }
        }
        if(!checkCurr){
            const denomDistributions = {
                dosMil: 0,
                mil: 0,
                quinientos: 0,
                dosCientos: 0,
                cien: 0,
                cincuenta: 0,
                veinteYCinco: 0,
                diez: 0,
                cinco: 0,
                uno: 0,
                puntoCinquenta: 0,
                puntoVeinteCinco: 0,
                puntoVeinte: 0,
                puntoQuince: 0,
                puntoDiez: 0,
                puntoCinco: 0,
                puntoUno: 0
            };
            arr.push({
                currencyId: currency,
                den: {
                    ...denomDistributions,
                    [id]: value
                }
            });
        }        
        setDenomTurn(arr);            
    }
    return (
        <div className='container-fluid'>
            {
                closing.closing!=null?
                <>
                    <BBox 
                        businessBox={closing.closing}
                        summary={closing.summary}
                    />
                    <div style={{marginTop: '1rem'}}>
                        <div className='col-8'>
                            <button 
                                type='button'
                                className='btn btn-link' 
                                title='Cuadre de hoy'
                                onClick={()=>location.reload()} 
                                style={{color: '#797676'}}>
                                    <FontAwesomeIcon icon={faCashRegister} /> <span style={{fontSize: 12}}>Cuadre de hoy</span>
                            </button>
                            {/* eslint-disable-next-line no-undef */}
                            <button 
                                className='btn btn-link' 
                                type='button'
                                title='Salir de la aplicación'
                                style={{marginLeft: '15px',color: '#797676'}}
                                onClick={()=>location.href=process.env.REACT_APP_PATH_APP}>
                                <FontAwesomeIcon icon={faRightFromBracket} /> <span style={{fontSize: 12}}>Salir de la aplicación</span>
                            </button>
                            <button 
                                className='btn btn-link' 
                                type='button'
                                title='Ir a factura'
                                onClick={()=>navigate('/pos')} style={{marginLeft: '15px', color: '#797676'}}>
                                <FontAwesomeIcon icon={faFileInvoice} /> <span style={{fontSize: 12}}>Ir a factura</span>
                            </button>
                        </div>
                    </div>                    
                </>
                :
                <div className='row'>
                    <div className='col-6 ni-height'>

                        <div className='container-fluid'>

                        <div className='row'>
                            <div className='col-12' style={{marginTop: '1rem',paddingRight: 0}}>
                                {/* eslint-disable-next-line no-undef */}
                                <button 
                                    className='btn btn-link' 
                                    type='button'
                                    title='Salir de la aplicación'
                                    style={{color: '#797676'}}
                                    onClick={()=>location.href=process.env.REACT_APP_PATH_APP}>
                                    <FontAwesomeIcon icon={faRightFromBracket} /> <span style={{fontSize: 12}}>Salir de la aplicación</span>
                                </button>
                                <button 
                                    className='btn btn-link' 
                                    type='button'
                                    title='Ir a factura'
                                    onClick={()=>navigate('/pos')} style={{marginLeft: '15px', color: '#797676'}}>
                                    <FontAwesomeIcon icon={faFileInvoice} /> <span style={{fontSize: 12}}>Ir a factura</span>
                                </button>
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <div className='col-12' style={{paddingRight: 0}}>
                                <div className="card">
                                    <div className="bg-card-header card-header">
                                        Cuadre de caja                                                                
                                    </div>
                                    <div className="card-body card-body-custom" style={{backgroundColor: '#fff'}}>
                                        {/* <div className='container-fluid'> */}
                                            {/* <div className='row'>
                                                <div className='col-12 table-response'> */}
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className='table-th-style'>
                                                                    Terminal                                                            
                                                                </th>
                                                                <th scope="col" className='table-th-style'>
                                                                    Fecha de apertura                                                            
                                                                </th>
                                                                <th scope="col" className='table-th-style'>
                                                                    Filtrar                                                            
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{textAlign: 'center'}} className='table-td-style'>
                                                                    {
                                                                        miscellaneous.terminal!=null&&miscellaneous.terminal!=undefined?
                                                                            miscellaneous.terminal.terminal_des
                                                                        : null
                                                                    }
                                                                </td>
                                                                <td style={{textAlign: 'center'}} className='table-td-style'>{miscellaneous.date}</td>
                                                                <td style={{textAlign: 'center'}} className='table-td-style'>
                                                                    <DateTimePicker
                                                                        onChange={setDateFilter}
                                                                        value={dateFilter}
                                                                        format="dd-MM-y"
                                                                        disableClock={true}
                                                                    />
                                                                    <button 
                                                                        className='btn btn-link' 
                                                                        title='Filtrar cierre'
                                                                        onClick={searchClosingByDate}>
                                                                        <FontAwesomeIcon icon={faSearch} />                                                                        
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                {/* </div>
                                            </div> */}
                                            {/* <div className='row'>
                                                <div className='col-12 table-response'> */}
                                                    <table className="table table-bordered table-sm">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className='table-th-style'></th>
                                                                {
                                                                    miscellaneous.currencies?
                                                                        miscellaneous.currencies.map((e,i)=>{
                                                                            return <th key={'currency-'+i} scope="col" className='table-th-style'>{e.currency_des+'('+e.currency_ref+')'}</th>
                                                                        })
                                                                    :null
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                miscellaneous.conditionWD?
                                                                    miscellaneous.conditionWD.map((e,i)=>{
                                                                        return [
                                                                            <tr key={i+'condition'}>
                                                                                <td className='table-td-style'>{e.condition_des}</td>
                                                                                {
                                                                                    e.currency.map((element,idx)=>{                                                                                
                                                                                        return <td key={'currency-condition-'+i+'-'+idx} style={{fontWeight: 'bold'}} className='table-td-style'>{nFormat(parseFloat(element.val).toFixed(2))}</td>
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                            /*,
                                                                            [
                                                                                e.condition_id == '5'?
                                                                                    <tr key={i+'child'}>
                                                                                        <td colSpan={miscellaneous.conditionWD.length+1}>
                                                                                        <ListGroup variant="flush">
                                                                                        <ListGroup.Item as="li" style={{background: '#eee'}}>
                                                                                            DENOMINACIONES POR VENTA(EN BRUTO)
                                                                                        </ListGroup.Item>
                                                                                            {
                                                                                                closing.denoms.map((elm,idn)=>{
                                                                                                    return <ListGroup.Item key={'denom-sell-'+idn} style={{fontWeight: 'bold'}}>{elm.denom_amount} x {elm.denom_qty}</ListGroup.Item>
                                                                                                })
                                                                                            }
                                                                                            </ListGroup>
                                                                                        </td>
                                                                                    </tr>
                                                                                :null
                                                                            ]*/
                                                                        ]
                                                                    })
                                                                :null
                                                            }
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td style={{textAlign: 'center',fontWeight: 'bold'}} className='table-ft-style'>Total:</td>
                                                                {                                                            
                                                                    currencySummary.map((currSumm,summIdx)=>{
                                                                        return <td style={{textAlign: 'center',fontWeight: 'bold'}} key={'currency-sum-'+summIdx} className='table-ft-style'>{nFormat(parseFloat(currSumm.amount).toFixed(2))}</td>
                                                                    })
                                                                }
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                {/* </div>
                                            </div> */}
                                        {/* </div> */}
                                        {
                                            handleData&&!isClosing ? <a href="#" className="btn btn-danger" onClick={closeBusinessBox} style={{marginLeft: '10px'}}>Cierre de caja</a> : null
                                        }                                                                    
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className='col-12' style={{paddingRight: 0}}>
                                <div className="card">
                                    <div className="card-header">
                                        Turno De Hoy
                                    </div>
                                    <div className="card-body card-body-custom">
                                        <div className='col-12'>
                                            <table className="table table-bordered table-sm">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className='table-th-style'>#Turno</th>
                                                        <th scope="col" className='table-th-style'>Terminal</th>
                                                        <th scope="col" className='table-th-style'>Desde</th>
                                                        <th scope="col" className='table-th-style'>Monto</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        miscellaneous.currentBb?
                                                        <>
                                                            <tr key={`box-${miscellaneous.currentBb.box_id}-1`}>
                                                                <td className='table-td-style'>{miscellaneous.currentBb.box_id}</td>
                                                                <td className='table-td-style'>{miscellaneous.currentBb.terminal_des}</td>
                                                                <td className='table-td-style'>{miscellaneous.currentBb.from_date}</td>
                                                                <td style={{fontWeight: 'bold'}} className='table-td-style'>{miscellaneous.currentBb.currency_ref+' '+miscellaneous.currentBb.open_amount}</td>                                                        
                                                            </tr>
                                                            <tr key={`box-${miscellaneous.currentBb.box_id}-2`}>
                                                                <td colSpan={4} className="align-left table-td-style">
                                                                    <span><strong>Nota</strong></span> 
                                                                    <br />
                                                                    <span style={{marginLeft: '5%'}}>{miscellaneous.currentBb.note}</span>
                                                                </td>
                                                            </tr>
                                                            <tr key={`box-${miscellaneous.currentBb.box_id}-3`} style={{backgroundColor: '#eee',fontWeight: 'bold'}}>
                                                                <td colSpan={4} className='table-td-style'>DENOMINACIONES EN APERTURA DE CAJA</td>
                                                            </tr>
                                                            {
                                                                miscellaneous.currentBb.denoms?
                                                                    miscellaneous.currentBb.denoms.map((cBElmenet,cBIdx)=>{
                                                                    return (
                                                                        <>                                                                
                                                                        <tr key={'cb'+ cBIdx}>
                                                                            <td style={{fontWeight: 'bold'}} colSpan={2} className='table-td-style'>
                                                                                {cBElmenet.currency_ref + ' ' + cBElmenet.denom_formatted}
                                                                                <span style={{marginLeft: '2px'}}>*</span>
                                                                                <span style={{marginLeft: '2px'}}>{cBElmenet.total_denom}</span>
                                                                            </td>
                                                                            <td style={{fontWeight: 'bold'}} colSpan={2} className='table-td-style'>{cBElmenet.currency_ref + ' ' + cBElmenet.sub_total}</td>
                                                                        </tr>
                                                                        </>
                                                                    )  
                                                                    })
                                                                :null
                                                            }
                                                        </>
                                                        :null
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <div className='col-12' style={{paddingRight: 0}}>
                                <div className="card">
                                    <div className="card-header bg-card-header">
                                        Ventas por usuarios
                                    </div>
                                    <div className="card-body card-body-custom">
                                        <div className='container-fluid'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <table className="table table-bordered table-sm">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className='table-th-style'>Usuario</th>
                                                                <th scope="col" className='table-th-style'>Condición</th>
                                                                <th scope="col" className='table-th-style'>Monto</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                miscellaneous.soldBySeller?
                                                                    miscellaneous.soldBySeller.map((e,i)=>{
                                                                        return (
                                                                            <tr key={'sold-by-seller'+i}>
                                                                                <td className='table-td-style'>{e.seller_name}</td>
                                                                                <td className='table-td-style'>{e.condition_des}</td>
                                                                                <td style={{fontWeight: 'bold'}} className='table-td-style'>{e.currency_ref+' '+e.total_amount_format}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                :null
                                                            }
                                                        </tbody>
                                                        {/* <tfoot>
                                                            <tr>
                                                                <td style={{textAlign: 'center',fontWeight: 'bold',fontSize: '18px'}}>Tota:</td>
                                                                {                                                            
                                                                    currencySummary.map((currSumm,summIdx)=>{
                                                                        return <td style={{textAlign: 'center',fontWeight: 'bold',fontSize: '18px'}} key={summIdx}>{nFormat(parseFloat(currSumm.amount).toFixed(2))}</td>
                                                                    })
                                                                }
                                                            </tr>
                                                        </tfoot> */}
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-3'>
                        {/* <div className='d-flex justify-content-center' style={{marginTop: '1rem'}}> */}
                            <div className='col-12' style={{paddingRight: 0}}>
                                <div className="card">
                                    <div className="card-header">
                                        Gastos del día
                                    </div>
                                    <div className="card-body card-body-custom">
                                        <div className='container-fluid'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <table className="table table-bordered table-sm">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className='table-th-style'>Usuario</th>
                                                                <th scope="col" className='table-th-style'>Condición</th>
                                                                <th scope="col" className='table-th-style'>Monto</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                miscellaneous.expenses?
                                                                    miscellaneous.expenses.map((e,i)=>{
                                                                        return (
                                                                            <tr key={'sold-by-seller'+i}>
                                                                                <td className='table-td-style'>{e.user_name}</td>
                                                                                <td className='table-td-style'>{e.condition_des}</td>
                                                                                <td style={{fontWeight: 'bold'}} className='table-td-style'>{e.currency_ref+' '+e.total_amount_formatted}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                :null
                                                            }
                                                        </tbody>
                                                        {/* <tfoot>
                                                            <tr>
                                                                <td style={{textAlign: 'center',fontWeight: 'bold',fontSize: '18px'}}>Tota:</td>
                                                                {                                                            
                                                                    currencySummary.map((currSumm,summIdx)=>{
                                                                        return <td style={{textAlign: 'center',fontWeight: 'bold',fontSize: '18px'}} key={summIdx}>{nFormat(parseFloat(currSumm.amount).toFixed(2))}</td>
                                                                    })
                                                                }
                                                            </tr>
                                                        </tfoot> */}
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {/* </div> */}
                        </div>

                        </div>

                    </div>
                    <div className='col-6 ni-height'>
                        <div className="card text-bg-success" style={{border: 'none'}}>
                            <div className="card-header" style={{display: 'none'}}>
                                Cuadre de caja                                                                
                            </div>
                            <div className="card-body card-body-custom" /*style={{height: '90vh',overflow: 'auto'}}*/ style={{backgroundColor: '#fff'}}>
                            {
                                    isClosing?
                                        <>
                                            {/* <div className='row'>
                                                <div className='col-12 table-response'> */}
                                                    <table className="table" style={{background: "#fff"}}>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={(miscellaneous.currencies!==undefined&&miscellaneous.currencies!==null)?miscellaneous.currencies.length+1:0}>Cierre cuadre de caja</th>
                                                            </tr>
                                                            <tr>
                                                                <th scope="col" className='table-th-style'>-</th>
                                                                {
                                                                    miscellaneous.currencies?
                                                                        miscellaneous.currencies.map((e,i)=>{
                                                                            return <th key={'currency-cuadre-de-caja-'+i} scope="col" className='table-th-style'>{e.currency_des+'('+e.currency_ref+')'}</th>
                                                                        })
                                                                    :null
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                miscellaneous.conditionWD?
                                                                    miscellaneous.conditionWD.map((e,i)=>{
                                                                        return [ 
                                                                            <>                                                                                
                                                                                {
                                                                                    e.condition_id=='5'?
                                                                                        <tr key={'DENOM-'+i}>
                                                                                            <td className='table-td-style'>
                                                                                                <ListGroup as="ol">
                                                                                                    <ListGroup.Item as="li" style={{padding: '0.5rem', fontWeight: 'bold'}}>$2,000.00</ListGroup.Item>
                                                                                                    <ListGroup.Item as="li" style={{padding: '0.5rem',fontWeight: 'bold'}}>$1,000.00</ListGroup.Item>
                                                                                                    <ListGroup.Item as="li" style={{padding: '0.5rem',fontWeight: 'bold'}}>$500.00</ListGroup.Item>
                                                                                                    <ListGroup.Item as="li" style={{padding: '0.5rem',fontWeight: 'bold'}}>$200.00</ListGroup.Item>
                                                                                                    <ListGroup.Item as="li" style={{padding: '0.5rem',fontWeight: 'bold'}}>$100.00</ListGroup.Item>
                                                                                                    <ListGroup.Item as="li" style={{padding: '0.5rem',fontWeight: 'bold'}}>$50.00</ListGroup.Item>
                                                                                                    <ListGroup.Item as="li" style={{padding: '0.5rem',fontWeight: 'bold'}}>$25.00</ListGroup.Item>
                                                                                                    <ListGroup.Item as="li" style={{padding: '0.5rem',fontWeight: 'bold'}}>$10.00</ListGroup.Item>
                                                                                                    <ListGroup.Item as="li" style={{padding: '0.5rem',fontWeight: 'bold'}}>$5.00</ListGroup.Item>
                                                                                                    <ListGroup.Item as="li" style={{padding: '0.5rem',fontWeight: 'bold'}}>$1.00</ListGroup.Item>
                                                                                                    <ListGroup.Item as="li" style={{padding: '0.5rem',fontWeight: 'bold'}}>$0.50</ListGroup.Item>
                                                                                                    <ListGroup.Item as="li" style={{padding: '0.5rem',fontWeight: 'bold'}}>$0.25</ListGroup.Item>
                                                                                                    <ListGroup.Item as="li" style={{padding: '0.5rem',fontWeight: 'bold'}}>$0.20</ListGroup.Item>
                                                                                                    <ListGroup.Item as="li" style={{padding: '0.5rem',fontWeight: 'bold'}}>$0.15</ListGroup.Item>
                                                                                                    <ListGroup.Item as="li" style={{padding: '0.5rem',fontWeight: 'bold'}}>$0.10</ListGroup.Item>
                                                                                                    <ListGroup.Item as="li" style={{padding: '0.5rem',fontWeight: 'bold'}}>$0.05</ListGroup.Item>
                                                                                                    <ListGroup.Item as="li" style={{padding: '0.5rem',fontWeight: 'bold'}}>$0.01</ListGroup.Item>
                                                                                                </ListGroup>
                                                                                            </td>
                                                                                            {
                                                                                                e.currency.map((currElement,currIdx)=>{
                                                                                                    return <>
                                                                                                            <td key={'currency-denom-list-'+i+'-'+currIdx} className='table-td-style'>
                                                                                                                <ListGroup as="ol">
                                                                                                                    <ListGroup.Item as="li" style={{padding: '0',fontWeight: 'bold'}}><input type="number" className="form-control form-control-sm" id="dosMil" curr={currElement.currency_id} onChange={handleDenom} placeholder={'$2,000.00'} /></ListGroup.Item>
                                                                                                                    <ListGroup.Item as="li" style={{padding: '0',fontWeight: 'bold'}}><input type="number" className="form-control form-control-sm" id="mil" curr={currElement.currency_id} onChange={handleDenom} placeholder={'$1,000.00'} /></ListGroup.Item>
                                                                                                                    <ListGroup.Item as="li" style={{padding: '0',fontWeight: 'bold'}}><input type="number" className="form-control form-control-sm" id="quinientos" curr={currElement.currency_id} onChange={handleDenom} placeholder={'$500.00'} /></ListGroup.Item>
                                                                                                                    <ListGroup.Item as="li" style={{padding: '0',fontWeight: 'bold'}}><input type="number" className="form-control form-control-sm" id="dosCientos" curr={currElement.currency_id} onChange={handleDenom} placeholder={'$200.00'} /></ListGroup.Item>
                                                                                                                    <ListGroup.Item as="li" style={{padding: '0',fontWeight: 'bold'}}><input type="number" className="form-control form-control-sm" id="cien" curr={currElement.currency_id} onChange={handleDenom} placeholder={'$100.00'} /></ListGroup.Item>
                                                                                                                    <ListGroup.Item as="li" style={{padding: '0',fontWeight: 'bold'}}><input type="number" className="form-control form-control-sm" id="cincuenta" curr={currElement.currency_id} onChange={handleDenom} placeholder={'$50.00'} /></ListGroup.Item>
                                                                                                                    <ListGroup.Item as="li" style={{padding: '0',fontWeight: 'bold'}}><input type="number" className="form-control form-control-sm" id="veinteYCinco" curr={currElement.currency_id} onChange={handleDenom} placeholder={'$25.00'} /></ListGroup.Item>
                                                                                                                    <ListGroup.Item as="li" style={{padding: '0',fontWeight: 'bold'}}><input type="number" className="form-control form-control-sm" id="diez" curr={currElement.currency_id} onChange={handleDenom} placeholder={'$10.00'} /></ListGroup.Item>
                                                                                                                    <ListGroup.Item as="li" style={{padding: '0',fontWeight: 'bold'}}><input type="number" className="form-control form-control-sm" id="cinco" curr={currElement.currency_id} onChange={handleDenom} placeholder={'$5.00'} /></ListGroup.Item>
                                                                                                                    <ListGroup.Item as="li" style={{padding: '0',fontWeight: 'bold'}}><input type="number" className="form-control form-control-sm" id="uno" curr={currElement.currency_id} onChange={handleDenom} placeholder={'$1.00'} /></ListGroup.Item>
                                                                                                                    <ListGroup.Item as="li" style={{padding: '0',fontWeight: 'bold'}}><input type="number" className="form-control form-control-sm" id="puntoCinquenta" curr={currElement.currency_id} onChange={handleDenom} placeholder={'$0.50'} /></ListGroup.Item>
                                                                                                                    <ListGroup.Item as="li" style={{padding: '0',fontWeight: 'bold'}}><input type="number" className="form-control form-control-sm" id="puntoVeinteCinco" curr={currElement.currency_id} onChange={handleDenom} placeholder={'$0.25'} /></ListGroup.Item>
                                                                                                                    <ListGroup.Item as="li" style={{padding: '0',fontWeight: 'bold'}}><input type="number" className="form-control form-control-sm" id="puntoVeinte" curr={currElement.currency_id} onChange={handleDenom} placeholder={'$0.20'} /></ListGroup.Item>
                                                                                                                    <ListGroup.Item as="li" style={{padding: '0',fontWeight: 'bold'}}><input type="number" className="form-control form-control-sm" id="puntoQuince" curr={currElement.currency_id} onChange={handleDenom} placeholder={'$0.15'} /></ListGroup.Item>
                                                                                                                    <ListGroup.Item as="li" style={{padding: '0',fontWeight: 'bold'}}><input type="number" className="form-control form-control-sm" id="puntoDiez" curr={currElement.currency_id} onChange={handleDenom} placeholder={'$0.10'} /></ListGroup.Item>
                                                                                                                    <ListGroup.Item as="li" style={{padding: '0',fontWeight: 'bold'}}><input type="number" className="form-control form-control-sm" id="puntoCinco" curr={currElement.currency_id} onChange={handleDenom} placeholder={'$0.05'} /></ListGroup.Item>
                                                                                                                    <ListGroup.Item as="li" style={{padding: '0',fontWeight: 'bold'}}><input type="number" className="form-control form-control-sm" id="puntoUno" curr={currElement.currency_id} onChange={handleDenom} placeholder={'$0.01'} /></ListGroup.Item>
                                                                                                                </ListGroup>                                                                                                        
                                                                                                            </td>                                                                                                    
                                                                                                        </>
                                                                                                })
                                                                                            }
                                                                                        </tr>
                                                                                    :null
                                                                                }
                                                                                <tr key={'currency-denom-cond-'+i}>
                                                                                    <td className='table-td-style'>{e.condition_des}</td>
                                                                                    {
                                                                                        e.currency.map((element,idx)=>{
                                                                                            let totalAmountByCurrency = 0;
                                                                                            if(denomTotal.length){
                                                                                                for(let ml=0;ml<denomTotal.length;ml++){
                                                                                                    if(element.currency_id==denomTotal[ml].currency&&e.condition_id=='5')
                                                                                                        totalAmountByCurrency += parseFloat(denomTotal[ml].total);
                                                                                                }
                                                                                            }
                                                                                            return <td key={'dis-cond-'+i+'-'+idx} className='table-td-style'>
                                                                                                    {
                                                                                                        e.condition_id=='5'?
                                                                                                            <input type="number" cond={e.condition_id} curr={element.currency_id} className="form-control form-control-sm" onChange={setDistribution} readOnly={e.condition_id=='5'?true:false} value={totalAmountByCurrency} />
                                                                                                        : <input type="number" cond={e.condition_id} curr={element.currency_id} className="form-control form-control-sm" onChange={setDistribution} />
                                                                                                    }                                                                                                    
                                                                                                </td>
                                                                                        })
                                                                                    }
                                                                                </tr>                                                                                    
                                                                            </>
                                                                        ]
                                                                    })
                                                                :null
                                                            }
                                                            <tr>
                                                                <td colSpan={(miscellaneous.currencies!==undefined&&miscellaneous.currencies!==null)?miscellaneous.currencies.length+1:0}>
                                                                    <SelectGroup 
                                                                        onChange={(input)=>{
                                                                            const value = input.target.value;
                                                                            setWhoClosed(value);
                                                                        }} 
                                                                        value={whoClosed} 
                                                                        generalClassName="default-general-class"
                                                                        className="col-12" 
                                                                        // label={
                                                                        //     <>
                                                                        //         ¿El cliente utiliza una mesa?
                                                                        //     </>
                                                                        // }
                                                                        inputName="turnCloser" 
                                                                        id="turnCloser"
                                                                        options={closers}
                                                                        inputClassName='form-control-sm'
                                                                        defaultOptionValue="¿Quien está haciendo este cierre?"
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={(miscellaneous.currencies!==undefined&&miscellaneous.currencies!==null)?miscellaneous.currencies.length+1:0}>
                                                                    {
                                                                        closeLoading?
                                                                            <Button variant="primary" disabled>
                                                                                <Spinner
                                                                                as="span"
                                                                                animation="grow"
                                                                                size="sm"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                                />
                                                                                Loading...
                                                                            </Button>
                                                                        :
                                                                            <a href="#" className="btn btn-primary" onClick={processCloseBusinessBox}>
                                                                                <FontAwesomeIcon icon={faCheckCircle} /> Cuadre Validado, Procesar Cierre
                                                                            </a>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                {/* </div>
                                            </div> */}
                                            {/* <div className='row'>
                                                <div className='col-12'>
                                                    
                                                </div>
                                            </div> */}
                                            {/* <div className='row'>
                                                <div className='col-12'> */}
                                                    {/* <a href="#" className="btn btn-danger" onClick={cancelCloseBusinessBox}>Cancelar cierre</a> */}                                                    
                                                {/* </div>
                                            </div> */}
                                        </>
                                    :null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }          
            
        </div>
    )
}
export default BusinessBox;