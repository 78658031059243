import React from "react";
import { Offcanvas,Card } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import { nFormat } from "../Vendor/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPrint, faTimes } from "@fortawesome/free-solid-svg-icons";
const SideBar = (props) => {
    const openAccount = (input) => {
        input.preventDefault();
        const id = input.currentTarget.getAttribute('id');
        console.log('OpenAccountID;::...',id);
        if(id){
            { /* eslint-disable-next-line react/prop-types */ }
            const accounts = props.accounts;
            console.log("Compare with:::...", accounts);
            // eslint-disable-next-line react/prop-types
            for(let i=0;i<accounts.length;i++)
            {
                const obj = accounts[i];
                // eslint-disable-next-line react/prop-types
                console.log("Compare with:::...", parseInt(obj.id), parseInt(id));                
                // eslint-disable-next-line react/prop-types
                if(parseInt(obj.id)==parseInt(id))
                {
                    { /* eslint-disable-next-line react/prop-types */ }
                    props.handleShoppingCart(obj.posDetail);
                    // eslint-disable-next-line react/prop-types
                    const inputCustomerOption = {value: obj.customer_id, label: obj.customer_label};
                    // eslint-disable-next-line react/prop-types
                    props.handleCusRef(inputCustomerOption);
                    // eslint-disable-next-line react/prop-types
                    props.handleAccountHeadId(obj.id);
                    // eslint-disable-next-line react/prop-types
                    props.handleShow();
                    // eslint-disable-next-line react/prop-types
                    props.setDisabledCustomerOption(true);
                    // eslint-disable-next-line react/prop-types
                    props.setDefaultCurrency(obj.currency_id);

                    // eslint-disable-next-line react/prop-types
                    if(obj.mesa_id!=null){
                        // eslint-disable-next-line react/prop-types
                        props.setTableAccount(obj.mesa_id);
                    } else {
                        // eslint-disable-next-line react/prop-types
                        props.setTableAccount(0);
                    }
                }
            }
        }
    }
    const removeAccount = (input) => {
        input.preventDefault();
        const id = input.currentTarget.getAttribute('id');
        if(id){
            Swal.fire({
                title: '¿Esta seguro de eliminar esta cuenta?',
                // showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Eliminar',
                // denyButtonText: `Don't save`,
            }).then((result)=>{
                if (result.isConfirmed) {
                    execRemoveAccount(id);
                } /*else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'info')
                }*/
            })
        }
    }
    const execRemoveAccount = async (id) => {
        try{
            // eslint-disable-next-line no-undef
            const resource = await axios.post(process.env.REACT_APP_PATH_API + 'remove/account',{
                account: id,
                token: localStorage.getItem('OSHEN-LOC-STR-TK')
            }),
            data = resource.data;
            if(data.status){
                // Swal.fire({
                //     icon: 'success',
                //     title: 'Éxito',
                //     text: data.msg
                // }).then(()=>{
                //     location.reload();
                // });
                // eslint-disable-next-line react/prop-types
                props.accountSetter(data.accounts);
                // eslint-disable-next-line react/prop-types
                props.setDisabledCustomerOption(false);
                // eslint-disable-next-line react/prop-types
                props.handleShoppingCart([]);
                // eslint-disable-next-line react/prop-types
                props.handleCusRef({value: 0, label: 'Seleccionar un cliente'});
                // eslint-disable-next-line react/prop-types
                props.handleAccountHeadId(0);
                // eslint-disable-next-line react/prop-types
                props.setTableAccount(0);
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Advertencia',
                    text: data.msg
                });
            }
        } catch(error){
            Swal.fire({
                icon: 'error',
                title: 'Error interno',
                text: 'Algo salio mal, favor intenta nuevamente ó contacte al administrador'
            });
        }
    }
    return (
        <>
            {/* eslint-disable-next-line react/prop-types*/}
            <Offcanvas show={props.show} onHide={props.handleShow}>
                <Offcanvas.Header closeButton>
                <Offcanvas.Title>Listado de cuentas</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body style={{padding: 0}}>                    
                    {
                        /* eslint-disable-next-line react/prop-types */
                        props.accounts!==undefined&&props.accounts!==null&&props.accounts.length? props.accounts.map((element,idx)=>{
                            return (
                                <Card 
                                    // bg="light" 
                                    key={idx} 
                                    style={{ 
                                        width: '100%',
                                        marginTop: '15px', 
                                        borderTop: 'none', 
                                        borderRight: 'none',
                                        borderLeft: 'none',
                                        borderBottom: '1px solid #ababa',
                                        borderRadius: '0'
                                    }}>
                                    <Card.Body>
                                        {
                                            element.mesaCount>0?
                                                <span className="badge text-bg-danger mb-3">
                                                    Cuenta en : #{element.mesaCount}
                                                </span>
                                            :null
                                        }                                        
                                        <Card.Title>{element.customer_name}</Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted">                                            
                                            Cuenta#{element.id}
                                            {
                                                (element.mesa_id!=null)?
                                                <>
                                                    <br /> <span className="badge text-bg-primary">{element.mesa_des}</span>
                                                </>
                                                :null
                                            }
                                        </Card.Subtitle>
                                        <Card.Text>
                                            {/* eslint-disable-next-line react/prop-types */}
                                            Monto. <span style={{fontWeight: 'bold',fontSize: '18px'}}>{nFormat(props.outDecimals==0?Math.round(parseFloat(element.total_amount)).toFixed(2):parseFloat(element.total_amount).toFixed(2))}</span>
                                        </Card.Text>
                                        <Card.Link id={element.id} cur={element.currency_id} href="#" onClick={openAccount} style={{marginLeft: '15vh'}}>
                                            <FontAwesomeIcon icon={faCheck} />
                                        </Card.Link>
                                        {/* eslint-disable-next-line no-undef */}
                                        <Card.Link href={`${process.env.REACT_APP_PATH_APP}print/pos/account/ticket/${element.id_md5}`} target="_blank">
                                            <FontAwesomeIcon icon={faPrint} />
                                        </Card.Link>
                                        {
                                            // eslint-disable-next-line react/prop-types
                                            props.removePermission?
                                                <Card.Link id={element.id} href="#" onClick={removeAccount} style={{color: '#f00'}}>
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </Card.Link>
                                            :null
                                        }                                        
                                    </Card.Body>
                                </Card>
                            )
                        }) : null
                    }                    
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
export default SideBar;